import React from "react"

import {
  HiddenInput,
  TextInput,
  SelectInput
} from "./Form"


export default props => {
  const errors = (fieldName) => props.errors[`${props.addressType}.${fieldName}`]
  const formFieldName = (fieldName) => `${props.formName}[${props.addressType}_attributes][${fieldName}]`

  return (
    <div>
      <HiddenInput
        defaultValue={props.attributes.id}
        name={formFieldName("id")}
      />

      <div className="row">
        <TextInput
          required
          label="Address Line 1"
          errors={errors("address_line_1")}
          wrapperHTMLClassName="col-sm-6"
          defaultValue={props.attributes.addressLine1}
          name={formFieldName("address_line_1")}
        />
        <TextInput
          label="Address Line 2"
          errors={errors("address_line_2")}
          wrapperHTMLClassName="col-sm-6"
          defaultValue={props.attributes.addressLine2}
          name={formFieldName("address_line_2")}
        />
      </div>

      <div className="row">
        <TextInput
          required
          label="City"
          errors={errors("city")}
          wrapperHTMLClassName="col-sm-3"
          defaultValue={props.attributes.city}
          name={formFieldName("city")}
        />
        <TextInput
          required
          label="State"
          errors={errors("state")}
          wrapperHTMLClassName="col-sm-3"
          defaultValue={props.attributes.state}
          name={formFieldName("state")}
        />
        <SelectInput
          includeBlank={false}
          label="Country"
          errors={errors("country")}
          wrapperHTMLClassName="col-sm-3"
          defaultValue={props.attributes.country || "Australia"}
          name={formFieldName("country")}
          options={props.countries.map(i => [i, i])}
        />
        <TextInput
          required
          label="Postcode"
          errors={errors("postcode")}
          wrapperHTMLClassName="col-sm-3"
          defaultValue={props.attributes.postcode}
          name={formFieldName("postcode")}
        />
      </div>
    </div>
  )
}
