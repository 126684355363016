import React from "react"

import classNames from "classnames"

import ControlGroup from "./ControlGroup"
import ControlLabel from "./ControlLabel"

export default ({
  className,
  errors,
  label,
  name,
  required,
  wrapperHTMLClassName,
  message,
  ...props
}) => {
  const id = name.replace(/\[/g, "_").replace(/\]/g, "")

  return (
    <ControlGroup
      className={["string", id, wrapperHTMLClassName]}
      error={!!errors}
      required={required}
    >
      <ControlLabel className="string" id={id} required={required}>
        {label}
      </ControlLabel>
      <div className="controls">
        <input
          className={classNames("form-control string", className, {
            required,
            optional: !required,
          })}
          id={id}
          type="text"
          name={name}
          required={required}
          aria-required={required}
          {...props}
        />
        {errors && <span className="help-inline">{errors}</span>}
      </div>
      {message && <span className="help-inline">{message}</span>}
    </ControlGroup>
  )
}
