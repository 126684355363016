import React from "react"

import classNames from "classnames"

export default ({ className, id, required, ...props }) => (
  <label
    className={classNames("control-label", className, {
      required,
      optional: !required,
    })}
    htmlFor={id}
  >
    {required && <abbr title="required">*</abbr>} {props.children}
  </label>
)
