import React, { useEffect, useState } from "react"

import {
  Form,
  RadioButtonGroup,
} from "./Form"

import 'react-credit-cards/lib/styles'

import DeliveryDetails from "./DeliveryDetails";
import InvoiceEmail from "./InvoiceEmail";
import CreditCardPaymentForm from "./CreditCardPaymentForm";
import { validateCreditCardDetails } from '../utils/creditcard'
import FullScreenLoader from "./FullScreenLoader";

export default props => {
  const [paymentType, setPaymentType] = useState(Object.keys(props.order.paymentTypes)[0])
  const [submittingOrder, setSubmittingOrder] = useState(false)
  const [validCreditCard, setValidCreditCard] = useState(false)
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false)
  const [displayAddress, setDisplayAddress] = useState((parseInt(props.selfPickUp)))
  const [displayInvoiceEmail, setInvoiceEmail] = useState(props.order.orderType == 'pos')

  useEffect(() => {
    $(document).on("keydown", ":input:not(textarea)", function(event) {
      return event.key != "Enter";
    })
  }, [])

  const actionButtonTitle = () => {
    return Object.entries(props.order.paymentTypes).find(([key, value]) => key == paymentType)[1].shortName
  }

  const submitOrder = e => {
    e.preventDefault()
    setDisplayValidationErrors(true)
    setSubmittingOrder(true)

    props.handleOrderUpdate({ checkout: true, paymentType: paymentType }).then(response => {
      if (paymentType == 'credit_card' && !validCreditCard) {
        setSubmittingOrder(false);
        return;
      }

      if (Object.keys(response).length > 0 && Object.keys(response.order.errors).length > 0) {
        if (response.order.errors.foreign_country == 1) {
          alert(`If you require international delivery please e-mail the winery for assistance with your order.`)
        }

        setSubmittingOrder(false);
        return;
      }

      if (paymentType == 'credit_card') {
        document.getElementById('payment-form').submit();
      } else if (['invoice', 'cash'].includes(paymentType)) {
        window.location.reload();
      } else {
        window.open(`${props.orderPath}.pdf`, '_blank');
      }
    })
  }
useEffect(() => {
  setDisplayAddress(parseInt(props.selfPickUp))
}, [props.selfPickUp])


  return (
    <div className="edit_order">
      {submittingOrder && <FullScreenLoader/>}
      <Form className="simple-form" id="order-form">

        {props.children}

        <hr/>

        {!([1, 2].includes(displayAddress)) && (
          <DeliveryDetails
            order={props.order}
            countries={props.countries}
            handleOrderUpdate={props.handleOrderUpdate}
          />
        )}

        {displayInvoiceEmail && (
          <InvoiceEmail
            order={props.order}
          />
        )}
      </Form>

      <h2 className="text-center">Payment</h2>
      <div className="payment">
        <div className="text-container">
          <p><strong>Payment Options:</strong></p>
          {Object.entries(props.order.paymentTypes).map(([key, value]) => <p key={key}>
            {value.description}
          </p>)}
        </div>

        <RadioButtonGroup
            name="payment_type"
            options={Object.entries(props.order.paymentTypes).map(([key, value]) => [value.longName, key])}
            wrapperHTMLClassName="order_payment_type"
            onChange={(e) => setPaymentType(e.target.value)}
            defaultChecked={paymentType}
        />
      </div>

      {paymentType == "credit_card" &&
        <CreditCardPaymentForm
          paymentGatewayData={props.order.paymentGatewayData}
          handleValidationChange={(valid) => setValidCreditCard(valid)}
          displayValidationErrors={displayValidationErrors}
          totalAmount={props.totalAmount}
        />
      }

      <div className="form-actions">
        <button
          className="btn btn-primary btn-lg text-uppercase"
          name="commit"
          onClick={submitOrder}
          disabled={submittingOrder}
        >
          {submittingOrder ? 'Processing...' : actionButtonTitle()}
        </button>
      </div>
    </div>
  )
}
