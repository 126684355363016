import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "subscribed", "customerGroup" ]

  connect() {
    this.updateSubscriptionCheckbox();
  }

  updateSubscriptionCheckbox(event) {
    let subscribable = this.customerGroupTarget.options[this.customerGroupTarget.selectedIndex].getAttribute('data-subscribable');

    if (subscribable == 'true') {
      this.subscribedTarget.classList.remove('hidden');
    } else {
      this.subscribedTarget.classList.add('hidden');
    }
  }
}
