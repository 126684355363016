import React from "react"

export default props => {
  const summaryCode = () => new URLSearchParams(window.location.search).get("summarycode");

  const successMessage = () => <div className="alert alert-success">{props.children}</div>;
  const errorMessage = () => (<div className="alert alert-danger">
    <h3>There was a problem with your payment.</h3>
    <p>Please contact your bank or use a different card.</p>
  </div>)

  if (props.orderStatus == 'eft_pending') {
    return successMessage()
  } else if (!summaryCode()) {
    return ''
  } else if (summaryCode() == "1") {
    return successMessage()
  } else {
    return errorMessage()
  }
}
