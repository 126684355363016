import React, { useEffect, useState } from "react"
import {CheckboxInput, WeekendRadioButtonGroup} from "./Form";

export default props => {
  let delivery_method = props.delivery_methods

  const openWeekendInformation = () => {
    return (
      <thead>
        <tr>
          <th>
            {props.order.releaseYear.openWeekendInformation && <p>
              {props.order.releaseYear.openWeekendInformation}
            </p>}

            {props.order.releaseYear.openWeekendPickUpCutoffDate && (<>
              <p>To collect your order from the winery please select the winery pick up option below and complete your payment no later than {props.order.releaseYear.orderPeriodEndOn}</p>
              <p>You can pick up your wine at our exclusive Members Allocation Weekend. You will receive the link and password to book up to two free tickets immediately after you pay for your order.</p>
              <p>If you can't make it to the Members Allocation Weekend please contact us to arrange pick up.</p>
              <p>If you do not wish to pick up your order from the winery we will ship your order to your delivery address.</p>
            </>) || (<>
              <p>To collect your order from the winery please select the winery pick up option below and complete your payment no later than {props.order.releaseYear.orderPeriodEndOn}</p>
              <p>You can pick up your wine at our exclusive Members Allocation Weekend. You will receive the link and password to book up to two free tickets immediately after you pay for your order.</p>
              <p>If you can't make it to the Members Allocation Weekend please contact us to arrange pick up.</p>
              <p>If you do not wish to pick up your order from the winery we will ship it to your delivery address.</p>
            </>)}
          </th>
        </tr>
      </thead>
    )
  }

  return (
    <div>
      {props.order.releaseYear.openWeekendPickupActive === true && props.showOpenWeekendInfo && (
        <h2 className="text-center">
          {props.order.releaseYear.year}
          &nbsp;
          {props.order.releaseYear.openWeekendPickUpCutoffDate && "Open Weekend" || "Order Pick Up" }
        </h2>
      )}

      <table className="table table-striped table-hover open-weekend">
        {props.order.releaseYear.openWeekendPickupActive === true && props.showOpenWeekendInfo && openWeekendInformation()}

        <tbody>
          {props.order.releaseYear.openWeekendPickupActive === true && props.showOpenWeekendInfo && (
            <tr>
              <td>
                <WeekendRadioButtonGroup
                  setSelfPickUp={props.setSelfPickUp}
                  name="order[attending_open_weekend]"
                  options={delivery_method}
                  wrapperHTMLClassName="order_payment_type"
                  onChange={props.handleOrderUpdate }
                  defaultChecked={props.order.attendingOpenWeekend}
                  errors={props.order.errors['attending_open_weekend']}
                />
              </td>
            </tr>
          )}
          <tr>
            <td className="text-center">Our Australia-wide delivery fee including insurance is ${props.order.releaseYear.deliveryFeePerDozen} per dozen bottles or part thereof. Please allow up to 4 weeks for delivery.</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
