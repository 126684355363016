import React, { useEffect, useState } from "react"
import { toast } from 'react-toastify';
import { emitOrderUpdatedEvent } from './utils/events'
import FoodTableListItem from "./UI/FoodTableListItem";
import 'react-toastify/dist/ReactToastify.css';
import CustomerFilter from "./CustomerFilter";

toast.configure({
  hideProgressBar: true
})

const ShoppingCartErrorMessage = (props) => (
  <div>
    <h4>Food Table errors</h4>
    <ul>
      {Object.entries(props.errors).map(([key, value]) => <li><span>{key}</span> {value}</li>)}
    </ul>
  </div>
)

export default (props) => {
  const addOrder = (foodTableId, customerId = null) => {  
    let userId;
    if (customerId) {
      userId = customerId
    } else {
      userId = document.getElementById('customers').value
    }
  
    if (!userId) {
      toast(<ShoppingCartErrorMessage errors={{ error: "Select Customer above before adding" }} />, { className: "shopping-cart-popup" })
      return
    }

    let body = new FormData();
    body.append("food_table_id", foodTableId);
    body.append("user_id", userId);

    return fetch(`${props.foodTablesAddOrderPath}`, {
      body: body,
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
      .then(response => response.json())
      .then(response => {
        if (Object.keys(response.foodTable.errors).length === 0) {
          emitOrderUpdatedEvent(response.foodTable);
          setShowCustomerFilter(false);
        } else {
          toast(<ShoppingCartErrorMessage {...response.foodTable} />, { className: "shopping-cart-popup" })
        }
        return response;
      })
      .catch(error => {
        toast(<ShoppingCartErrorMessage errors={ { error: "Unexpected error occurred"} } />, { className: "shopping-cart-popup" })
      });
  }

  const [showCustomerFilter, setShowCustomerFilter] = useState(false);
  const [tableId, setTableId] = useState('');
  const [tableNumber, setTableNumber] = useState('');

  const addMember = (tableId, tableNumber) => {
    setShowCustomerFilter(!showCustomerFilter);
    setTableId(tableId);
    setTableNumber(tableNumber);
  };

  return (
    <>
      <div className="row">
        {showCustomerFilter && (
          <CustomerFilter
            customerFilterPath={props.customerFilterPath}
            customerGroups={props.customerGroups}
            formName="food_table"
            tableId={tableId}
            tableNumber={tableNumber}
            handleAddOrder={addOrder}
          />
        )}
      </div>
      <div>
        {props.foodTables.length > 0 && (<>
          {(
            <div className="row">
              {props.foodTables.map(foodTableListItem => <FoodTableListItem
                  key={foodTableListItem.id}
                  foodTablesPath={props.foodTablesPath}
                  handleAddMember={addMember}
                  handleAddOrder={addOrder}
                  {...foodTableListItem}
                />
              )}
            </div>
          )}
        </>) || (<div className="food-tables-unavailable text-center">
          There are no food tables yet.
        </div>)}
      </div>
    </>
  )
}