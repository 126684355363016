import React, { useState } from "react"

import {
  TextAreaInput,
  CheckboxInput,
} from "./Form"

import AddressFields from "./AddressFields"

export default props => {
  const [DeliveryDetailsFormVisible, setDeliveryDetailsFormVisible] = useState(props.order.orderType != 'pos')

  //console.log(props.handleOrderUpdate)
  return (
    <div>
      <div className="row">
        {(props.order.orderType == 'pos') && (
          <CheckboxInput
            label=" Customer requires delivery"
            name="delivery_required"
            wrapperHTMLClassName="col-sm-12"
            onChange={(e) => {
              setDeliveryDetailsFormVisible(e.target.checked);
              props.handleOrderUpdate(false);
            }}
          />
        )}
      </div>

      {DeliveryDetailsFormVisible &&
        <div>
          {(props.order.orderType == 'pos') && (
            <div>
              <h2 className="text-center">Delivery Details</h2>

              <div className="text-center delivery_address">
                For members: Confirm the delivery address with the customer and add special delivery instructions if required.
                <br></br>
                For guests: Enter the delivery customer's delivery address and add special delivery instructions if required.
              </div>
            </div>
          ) || (
            <div>
              <h2 className="text-center">Your Delivery Details</h2>
              
              <div className="text-center delivery_address">
                Please review and update your Delivery Address and provide any Special Delivery Instructions below. If you will not be home please indicate that you are providing authority leave the delivery in a specified safe place.
              </div>
            </div>
          )}

          <AddressFields
            addressType="delivery_address"
            errors={props.order.errors}
            countries={props.countries}
            attributes={props.order.deliveryAddress}
            formName="order"
          />

          <div className="row">
            <TextAreaInput
              label="Special Delivery Instructions"
              wrapperHTMLClassName="col-sm-12"
              defaultValue={props.order.packingNote}
              name="order[packing_note]"
            />
          </div>

          {(props.order.orderType != 'pos') && (
            <div className="row">
              <CheckboxInput
                  label=" I confirm my delivery information as shown above is correct."
                  wrapperHTMLClassName="col-sm-12"
                  errors={props.order.errors['delivery_address.confirmed']}
                  name="order[delivery_address_attributes][confirmed]"
              />
            </div>
          )}
        </div>
      }
    </div>
  )
}
