import React from "react";

const FullScreenLoader = () => (
  <div className="full-screen-loader">
    <div className="loader-wrap">
      <div className="loader-glass"></div>
      <div className="loader-stem"></div>
      <div className="loader-base"></div>
    </div>
    <div className="full-screen-loader__spinner">Processing Payment...</div>
  </div>

);

export default FullScreenLoader;