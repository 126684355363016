import React, { useEffect, useState } from "react"

import OrderTable from "./UI/FoodTableOrderTable";
import OrderForm from "./UI/OrderForm";
import withOrder from "./withOrder";
import PaymentMessage from "./UI/PaymentMessage"

const FoodTableOrderForm = props => {
  if (props.order.status == 'paid') {
    return (
      <div>
        <PaymentMessage>
          <h3>Thank you for your order</h3>
          <p>Your payment has been successfully processed.</p>
        </PaymentMessage>

        <h2 className="text-center">Order</h2>
        <OrderTable {...props.order}/>
      </div>)
  } else {
    return (
      <OrderForm
        order={props.order}
        handleOrderUpdate={props.handleOrderUpdate}
        {...props}
      >
        <h2 className="text-center">Your Order</h2>
        <OrderTable
          handleOrderUpdate={props.handleOrderUpdate}
          {...props.order}
        />
      </OrderForm>
    )
  }
}

export default withOrder(FoodTableOrderForm)
