import React, { useEffect, useState } from 'react';
import { emitOrderUpdatedEvent } from './utils/events'

export default (props) => {
  const [order, setOrder] = useState({})
  const [orderPath, setOrderPath] = useState(props.orderPath)

  useEffect(() => fetchOrder(), []);
  useEffect(() => {
    document.addEventListener('orderUpdated', (e) => {
      setOrder(e.detail.order);
      setOrderPath(e.detail.order.url);
    })
  }, []);

  const fetchOrder = () => {
    if (!orderPath) {
      return
    }

    fetch(`${orderPath}.json`, {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
    .then(response => response.json())
    .then(response => {
      emitOrderUpdatedEvent(response.order);
    });
  }

  const orderTotalQuantity = () => {
    return (order.orderDetails || []).reduce((acc, orderDetail) => acc + orderDetail.quantity, 0)
  }

  return (
    <a href={orderPath} className="shopping-cart-button d-flex align-items-center">
      <div className={"fa-stack fa-2x " + (orderTotalQuantity() > 0 && "has-badge" || "")} data-count={orderTotalQuantity()}>
        <i className="fa fa-shopping-cart fa-stack-1x"></i>
      </div>
      {order.total && (
        <div className="d-flex align-items-center">
          <div className="total-wrapper">
            <div className="amount">{order.total}</div>
            <div>In your cart</div>
          </div>
          <div className="p-2"><i className="fa fa-chevron-right"></i></div>
        </div>
      )}
    </a>
  )
}
