import React, { useEffect, useState } from "react"
import Card from 'react-credit-cards';

import {
  Form,
  HiddenInput,
  TextInput,
} from "./Form"

import {
  clearNumber,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../utils/creditcard";

export default props => {
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState('')

  const [number, setNumber] = useState('')
  const [numberErrors, setNumberErrors] = useState('')

  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  const [expiry, setExpiry] = useState('')
  const [expiryErrors, setExpiryErrors] = useState('')

  const [ccv, setCCV] = useState('')
  const [ccvErrors, setCCVErrors] = useState('')

  const [issuer, setIssuer] = useState('')
  const [focus, setFocus] = useState('')

  let totalAmount = props.totalAmount

  if (totalAmount == undefined) {
    totalAmount = props.paymentGatewayData.amount
  } else {
    totalAmount = `${props.totalAmount}.00`
  }

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  }

  const validateCreditCard = () => {
    let valid = true

    if (!Payment.fns.validateCardNumber(number)) {
      setNumberErrors('Invalid card number')
      valid = false
    } else {
      setNumberErrors('')
    }

    if (name.length == 0) {
      setNameErrors("Name can't be blank")
      valid = false
    } else {
      setNameErrors('')
    }

    if (!Payment.fns.validateCardExpiry(month, year)) {
      setExpiryErrors('Invalid expiration date')
      valid = false
    } else {
      setExpiryErrors('')
    }

    let cardType = Payment.fns.cardType(number);
    if (!Payment.fns.validateCardCVC(ccv, cardType)) {
      setCCVErrors('Invalid CCV')
      valid = false
    } else {
      setCCVErrors('')
    }

    props.handleValidationChange(valid)
  }

  useEffect(validateCreditCard, [props.displayValidationErrors, number, name, expiry, ccv])

  const handleNumberChange = (e) => {
    e.target.value = formatCreditCardNumber(e.target.value)
    setNumber(e.target.value)
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleExpiryChange = (e) => {
    e.target.value = formatExpirationDate(e.target.value)
    setExpiry(e.target.value)

    let [month, year] = e.target.value.split('/')
    setMonth(month)
    setYear(year)
  }

  const handleCCVChange = (e) => {
    e.target.value = formatCVC(e.target.value)
    setCCV(e.target.value)
  }

  return (
    <div>
      <Form id="payment-form" action={props.paymentGatewayData.paymentGatewayUrl}>
        <HiddenInput
          name="EPS_RESULTPARAMS"
          value={props.paymentGatewayData.resultParams}
        />
        <HiddenInput
          name="EPS_MERCHANT"
          value={props.paymentGatewayData.merchant}
        />
        <HiddenInput
          name="EPS_TXNTYPE"
          value={props.paymentGatewayData.txnType}
        />
        <HiddenInput
          name="EPS_REFERENCEID"
          value={props.paymentGatewayData.referenceId}
        />
        <HiddenInput
          name="EPS_AMOUNT"
          value={totalAmount}
        />
        <HiddenInput
          name="EPS_TIMESTAMP"
          value={props.paymentGatewayData.timestamp}
        />
        <HiddenInput
          name="EPS_FINGERPRINT"
          value={props.paymentGatewayData.fingerprint}
        />
        <HiddenInput
          name="EPS_RESULTURL"
          value={props.paymentGatewayData.resultUrl}
        />
        <HiddenInput
          name="EPS_REDIRECT"
          value="TRUE"
        />
        <HiddenInput
          name="EPS_CALLBACKURL"
          value={props.paymentGatewayData.callbackUrl}
        />
        <HiddenInput
          name="EPS_CARDNUMBER"
          value={clearNumber(number)}
        />
        <HiddenInput
          name="EPS_EXPIRYMONTH"
          defaultValue={month}
        />
        <HiddenInput
          name="EPS_EXPIRYYEAR"
          defaultValue={year}
        />
        <HiddenInput
          name="EPS_CCV"
          value={ccv}
        />
      </Form>

      <Form>
        <div className="row">
          <div className="col-md-6 credit-card-component d-none d-md-block">
            <Card
              number={number}
              expiry={expiry}
              name={name}
              cvc={ccv}
              callback={handleCallback}
              issuer={issuer}
              focused={focus}
            />
          </div>
          <div className="col-md-6">
            <div className="row">
              <TextInput
                required
                name="number"
                label="Card Number"
                onChange={handleNumberChange}
                onFocus={handleInputFocus}
                errors={props.displayValidationErrors && numberErrors || ''}
                wrapperHTMLClassName="col-sm-12"
                pattern="[\d| ]{16,22}"
              />
              <TextInput
                required
                name="name"
                label="Name"
                onChange={handleNameChange}
                onFocus={handleInputFocus}
                errors={props.displayValidationErrors && nameErrors || ''}
                wrapperHTMLClassName="col-sm-12"
              />
            </div>
            <div className="row">
              <TextInput
                required
                name="expiry"
                label="Expiry"
                onChange={handleExpiryChange}
                onFocus={handleInputFocus}
                errors={props.displayValidationErrors && expiryErrors || ''}
                wrapperHTMLClassName="col-sm-8"
                pattern="\d\d/\d\d"
                placeholder="MM/YY"
              />
              <TextInput
                required
                name="cvc"
                label="CCV"
                onChange={handleCCVChange}
                onFocus={handleInputFocus}
                errors={props.displayValidationErrors && ccvErrors || ''}
                wrapperHTMLClassName="col-sm-4"
                pattern="\d{3,4}"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}
