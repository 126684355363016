import React, { useEffect, useState } from "react"

import {
  Form,
  CheckboxInput,
} from "./UI/Form"

import 'react-credit-cards/lib/styles'

import CreditCardPaymentForm from "./UI/CreditCardPaymentForm";
import { validateCreditCardDetails } from './utils/creditcard'

export default props => {
  const [paymentFormVisible, setPaymentFormVisible] = useState(false)
  const [submittingOrder, setSubmittingOrder] = useState(false)
  const [validCreditCard, setValidCreditCard] = useState(false)
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false)
  const [order, setOrder] = useState(props.order)

  useEffect(() => {
    $(document).on("keydown", ":input:not(textarea)", function (event) {
      return event.key != "Enter";
    })
  }, [])

  const updateOrder = () => {
    let body = new FormData(document.getElementById(props.orderFormId))

    return fetch(props.orderPath, {
      body: body,
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
    .then(response => response.json())
    .then(response => {
      if (response.order) {
        setOrder(response.order)
      } else {
        handleUnexpectedError("Unexpected error occurred while updating the order");
      }
      return response;
    });
  }

  const submitOrder = e => {
    e.preventDefault()
    setDisplayValidationErrors(true)
    setSubmittingOrder(true)

    updateOrder().then(response => {
      if (!validCreditCard) {
        setSubmittingOrder(false);
        return;
      }

      if (Object.keys(response).length > 0 && Object.keys(response.order.errors).length > 0) {
        setSubmittingOrder(false);
        return;
      }

      document.getElementById('payment-form').submit();
    })
  }

  const submitOrderWithoutPayment = e => {
    e.preventDefault()
    setDisplayValidationErrors(true)

    document.getElementById(props.orderFormId).submit();
  }

  return (
    <div className="edit_order">
      <Form className="simple-form" id="order-form">
        {props.children}
      </Form>

      <CheckboxInput
        label="Pay by credit card now"
        name="payment_type"
        wrapperHTMLClassName="order_payment_type"
        onChange={(e) => setPaymentFormVisible(e.target.checked)}
      />

      {paymentFormVisible &&
        <CreditCardPaymentForm
          paymentGatewayData={order.paymentGatewayData}
          handleValidationChange={(valid) => setValidCreditCard(valid)}
          displayValidationErrors={displayValidationErrors}
          resultUrl={props.orderUrl}
        />
      }

      {paymentFormVisible && (
        <div className="form-actions">
          <button
            className="btn btn-primary btn-lg text-uppercase"
            name="commit"
            onClick={submitOrder}
            disabled={submittingOrder}
          >
            {submittingOrder ? 'Processing...' : 'Submit payment'}
          </button>
        </div>
      )}

      {!paymentFormVisible && (
        <div className="form-actions">
          <button
            className="btn btn-primary btn-lg text-uppercase"
            name="commit"
            onClick={submitOrderWithoutPayment}
          >
            Update Order
          </button>
        </div>
      )}
    </div>
  )
}
