import React, { useEffect, useState } from "react"

import OrderTable from "./UI/AllocationOrderTable";
import OrderForm from "./UI/OrderForm";
import OpenWeekend from "./UI/OpenWeekend";
import withOrder from "./withOrder";
import PaymentMessage from "./UI/PaymentMessage"

const AllocationOrderForm = props => {
    const [additionalProducts, setAdditionalProducts] = useState([])
    const [productsPath, setProductsPath] = useState(null)
    const [totalAmount, setTotalAmount] = useState(props.showOpenWeekendInfo.amount)
    const orderId = props.order.id

    useEffect(() => fetchProducts(), []);
    const [selfPickUp, setSelfPickUp] = useState(props.order.attendingOpenWeekend)

    const fetchProducts = () => {
        let ignore = false;

        fetch(`${props.additionalProductsPath}.json`, {
            credentials: "include",
            headers: {
                Accept: "application/json",
            },
        }).then(response => response.json())
            .then(response => {
                setAdditionalProducts(response.additional_products)
                setProductsPath(response.productsPath)
            });
    }

    if (props.order.status == 'paid') {
        return (
            <div>
                <PaymentMessage>
                    <h3>Thank you for your order</h3>
                    <p>A copy of your invoice has been e-mailed to you.</p>
                    {props.order.attendingOpenWeekend && (
                        <div>
                            <p>To confirm your place at our exclusive Members Weekend on July 21st to 23rd, please follow the link and password below to book a session time.</p>
                            <a href="https://wildduckcreekestate.com.au/product-category/bookings">https://wildduckcreekestate.com.au/product-category/bookings</a>
                            <p>Password: WDCE23</p>
                            <p>Please do not share this confidential link and password with anyone. The allocation weekend is an exclusive free event for allocation holders + 1 person (2 tickets per allocation holder)</p>
                            <p>We look forward to seeing you soon.</p>
                        </div>
                    ) || (
                        <div>
                            <p>We will be processing and packing orders as fast as humanly possible.</p>
                            <p>It will likely be sooner, however please allow up to 8 weeks for delivery. Alternatively you’re welcome to arrange a pickup time from the winery. Please call us on 03 5433 3133, or email us at winery@wdce.com.au.</p>
                        </div>
                    )}
                </PaymentMessage>
                <h2 className="text-center">Your {props.order.releaseYear.year} Order</h2>
                <OrderTable additionalProducts={additionalProducts !== null ? additionalProducts.filter(product => product.quantity !== 0) : []}
                            productsPath={productsPath}
                            orderId={orderId}
                            {...props.order}
                            setTotalAmount={setTotalAmount}
                            selfPickUp={selfPickUp}
                            paid={true}/>
            </div>)
    } else {
        return (
            <OrderForm
                order={props.order}
                handleOrderUpdate={props.updateOrder}
                {...props}
                totalAmount={totalAmount}
                selfPickUp={selfPickUp}
            >
                <h2 className="text-center">Your {props.order.releaseYear.year} {props.currentAllocationPeriod} Release</h2>
                <OrderTable
                    additionalProducts={additionalProducts}
                    productsPath={productsPath}
                    orderId={orderId}
                    selfPickUp={selfPickUp}
                    {...props.order}
                    setTotalAmount={setTotalAmount}
                    paid={false}/>

                <hr/>

                <OpenWeekend
                    order={props.order}
                    showOpenWeekendInfo={props.showOpenWeekendInfo}
                    handleAttendingOpenWeekendChange={props.updateOrder}
                    setSelfPickUp={setSelfPickUp}
                    {...props}
                />
            </OrderForm>
        )
    }
}

export default withOrder(AllocationOrderForm)
