import React from "react"

import {
  TextInput,
} from "./Form"

export default props => {
  return (
    <div>
      <h2 className="text-center">Invoice Email Address</h2>

      <div className="text-center delivery_address">
        For members: Confirm the customer's e-mail address is correct.
        <br></br>
        For guests: Enter the guest's e-mail address.
      </div>

      <div>
        <TextInput
          label="E-mail address"
          id="invoice_email"
          errors={props.order.errors['invoice_email']}
          wrapperHTMLClassName="col-sm-6"
          defaultValue={props.order.invoiceEmail}
          name="order[invoice_email]"
      />
      </div>
    </div>
  )
}
