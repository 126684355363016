import React from "react"

import classNames from "classnames"

import ControlGroup from "./ControlGroup"
import ControlLabel from "./ControlLabel"

export default ({ className, errors, label, name, required, wrapperHTMLClassName, ...props }) => {
  const id = name.replace(/\[/g, "_").replace(/\]/g, "")

  return (
    <ControlGroup
      className={["text", id, wrapperHTMLClassName]}
      error={!!errors}
      required={required}
    >
      <ControlLabel className="text" id={id} required={required}>
        {label}
      </ControlLabel>
      <div className="controls">
        <textarea
          className={classNames("form-control text", className, {
            required,
            optional: !required,
          })}
          id={id}
          name={name}
          required={required}
          aria-required={required}
          {...props}
        />
        {errors && <span className="help-inline">{errors}</span>}
      </div>
    </ControlGroup>
  )
}
