import React from "react"

import classNames from "classnames"

import ControlGroup from "./ControlGroup"
import ControlLabel from "./ControlLabel"

export default ({ name, ...props }) => {
  const id = name.replace(/\[/g, "_").replace(/\]/g, "")

  return (
    <ControlGroup className={["hidden", id]}>
      <div className="controls">
        <input className="form-control hidden" type="hidden" id={id} name={name} {...props} />
      </div>
    </ControlGroup>
  )
}
