import React, {Fragment, useEffect, useState} from "react"
import {emitOrderUpdatedEvent} from "../utils/events";
import {toast} from "react-toastify";
// import Select from 'react-select'
const OrderLineItem = props => {
    return(
        <tr>
            <td>{props.productName}</td>
            <td>{props.vintageYear}</td>
            <td className="text-right">{props.price}</td>
            <td className="text-right">{props.standingOrderRequestQuantity}</td>
            <td className="text-right">{props.quantity}</td>
            <td className="text-right">{props.total}</td>
        </tr>
    )
}

const OrderTotal = props => {
    return(
        <tr className="order-total">
            <td className="cell-label cell-label--number" colSpan="5">
                <strong>{props.name}</strong>
            </td>
            <td className="cell-data cell-data--number">{(props.currency === true) ? '$' : ''}{props.value}{(props.currency === true) ? '.00' : ''}</td>
        </tr>
    )
}

const OrderTotalAdditional = props => {
    return(
        <tr className="order-total">
            <td className="cell-label cell-label--number" colSpan="4">
                <strong>{props.name}</strong>
            </td>
            <td className="cell-data cell-data--number">{(props.currency === true) ? '$' : ''}{props.value}{(props.currency === true) ? '.00' : ''}</td>
        </tr>
    )
}

const orderedCount = (orderDetails, product_id) => {

    if ((orderDetails == undefined) || (orderDetails.length == 0)) {return 0}
    var orderDetail = orderDetails.find(order_detail => order_detail.productId == product_id)

    return orderDetail == undefined ? 0 : orderDetail.quantity
}

// const calculateMaxForSelect = (currentSelect, maxPerOrder, initialMaxQuantity) => {
//     if (maxPerOrder === 0) return 0
//
//     return Math.max(maxPerOrder, currentSelect)
//
// }
const OrderAdditionalWineLineItem = props => {
    const id = props.id
    const price = Number(props.price.replace(/[^0-9.-]+/g,""))
    const initialMaxQuantity = props.initialMaxQuantity
    const [currentSelect, setCurrentSelect] = useState(orderedCount(props.orderedAdditionalProducts, id))
    const [currentTotal, setCurrentTotal] = useState(price * currentSelect)
    const productsPath = props.productsPath
    const orderId = props.orderId

    // let maxOptionValue = calculateMaxForSelect(currentSelect, props.maxPerOrder, initialMaxQuantity)
    let quantitiesAvailableToCustomer = props.quantitiesAvailableToCustomer
    let optionsForSelect = quantitiesAvailableToCustomer
        .filter(value => value <= initialMaxQuantity)
        .map(value => (
            <option key={value} value={value}>{value}</option>
        ));

    if (props.paid === false) {
    return(
        <tr>
            <td>{props.name}</td>
            <td>{props.vintageYear}</td>
            <td className="text-right">{props.price}</td>
            <td className="text-right">
                {selectAdditionalWineQuantity(props.maxPerOrder, optionsForSelect, id, orderId, productsPath, price, currentSelect, setCurrentSelect, setCurrentTotal, props.handleBottlesCountChange, addToCart)}

            </td>
            <td className="text-right">${currentTotal}.00</td>
        </tr>
    )} else {
        return(
            <tr>
                <td>{props.name}</td>
                <td>{props.vintageYear}</td>
                <td className="text-right">{props.price}</td>
                <td className="text-right">
                    {currentSelect}
                </td>
                <td className="text-right">${currentTotal}.00</td>
            </tr>
        )
    }
}


const selectAdditionalWineQuantity = (maxPerOrder, optionsForSelect, id, orderId, productsPath, price, currentSelect, setCurrentSelect, setCurrentTotal, handleBottlesCountChange, addToCart) => {
    if (maxPerOrder == 0 && currentSelect == 0) {
        return (
            <p>Out of Stock</p>
        )
    } else {
        return (
            <select
                value={currentSelect}
                className="additional-wine-quantity-select"
                onChange={
                    e => {
                        // let differenceBottles = e.target.value - currentSelect
                        // let differenceMoney = (e.target.value - currentSelect) * parseInt(props.price.substring(1))
                        // // request permit to add quantity
                        addToCart(id, e.target.value, orderId, productsPath, price, currentSelect, setCurrentSelect, setCurrentTotal, handleBottlesCountChange)
                        // setCurrentSelect(e.target.value);
                        // setCurrentTotal(e.target.value * price)
                        // props.handleBottlesCountChange(differenceBottles, differenceMoney)
                    }

                }>
                {optionsForSelect}
            </select>
        )
    }















//     if (optionsForSelect.length > 1 || currentSelect > 0) {
//         return (
//             <select
//                 value={currentSelect}
//                 className="additional-wine-quantity-select"
//                 onChange={
//                     e => {
//                         // let differenceBottles = e.target.value - currentSelect
//                         // let differenceMoney = (e.target.value - currentSelect) * parseInt(props.price.substring(1))
//                         // // request permit to add quantity
//                         addToCart(id, e.target.value, orderId, productsPath, price, currentSelect, setCurrentSelect, setCurrentTotal, handleBottlesCountChange)
//                         // setCurrentSelect(e.target.value);
//                         // setCurrentTotal(e.target.value * price)
//                         // props.handleBottlesCountChange(differenceBottles, differenceMoney)
//                     }
//
//                 }>
//                 {optionsForSelect}
//             </select>
//         )
//     } else {
// return (
//     <p>Out of Stock</p>
// )
//     }



}

const ShoppingCartErrorMessage = (props) => (
    <div>
        <h4>Order errors</h4>
        <ul>
            {Object.entries(props.errors).map(([key, value]) => <li><span>{key}</span> {value}</li>)}
        </ul>
    </div>
)

const addToCart = (productId, quantity, orderId, productsPath, price, currentSelect, setCurrentSelect, setCurrentTotal, handleBottlesCountChange) => {
    if (quantity.toString().length == 0) {
        return;
    }



    let body = new FormData();
    body.append("quantity", quantity);
    body.append("product_id", productId);
    body.append("order_id", orderId);

    return fetch(`${productsPath}.json`, {
        body: body,
        method: "PATCH",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        },
    })
        .then(response => response.json())
        .then(response => {
            if (Object.keys(response.order.errors).length === 0) {
                let differenceBottles = quantity - currentSelect
                let differenceMoney = (quantity - currentSelect) * price

                setCurrentSelect(quantity);
                setCurrentTotal(quantity * price)

                handleBottlesCountChange(differenceBottles, differenceMoney)
            } else {
                let max_value = response.order.errors.left_in_stock
                alert(`Only ${max_value} bottles left in stock`)

                let differenceBottles = max_value - currentSelect
                let differenceMoney = (max_value - currentSelect) * price

                setCurrentSelect(max_value)
                setCurrentTotal(max_value * price)

                handleBottlesCountChange(differenceBottles, differenceMoney)
            }
            return response;
        })
        .catch(error => {
            // toast(<ShoppingCartErrorMessage errors={ { error: "Unexpected error occurred"} } />, { className: "shopping-cart-popup" })
        });
}

const sumElements = (arr) => {
    if (arr.length==0) {return 0}
    const reducer = (accumulator, curr) => accumulator + curr;

    return (arr.reduce(reducer));
}

const calculateDeliveryFee = (totalBottles, allocationProductsPrice, additionalProductsPrice, selfPickUp, deliveryFeeOverride, initialDeliveryFee) => {
    if (deliveryFeeOverride == true) return initialDeliveryFee

    const defaultDeliveryFee = 18
    const deliveryFeeThreshold = 2000

    if (selfPickUp == 1 || selfPickUp == 2) {return 0}
    if ((additionalProductsPrice + allocationProductsPrice) > deliveryFeeThreshold) {return 0}
    return Math.ceil(totalBottles / 12) * defaultDeliveryFee
}

// TODO: refactor this madness
export default props => {
    // Allocation
    const paid = props.paid

    let selfPickUp = props.selfPickUp
    const deliveryFeeOverride = props.deliveryFeeOverride || false
    const initialDeliveryFee = parseInt(props.deliveryFee.replace(/[^0-9.]/g, "")) || 18

    const allocationProducts = props.orderDetails.filter(item => !item.customerGroupsNames.includes('Allocation Additional Products'))
    var allocationBottlesCount = sumElements(allocationProducts.map(item => { return item.quantity}))
    const currentTotalAllocationPrice = sumElements(allocationProducts.map(item => {return Number(item.total.replace(/[^0-9.-]+/g,""))}))

    // ADDITIONAL PRODUCTS
    const orderedAdditionalProducts = props.orderDetails.filter(item => item.customerGroupsNames.includes('Allocation Additional Products'))
    const [currentAdditionalBottlesCount, setCurrentAdditionalBottlesCount] = useState(sumElements(orderedAdditionalProducts.map(item => { return item.quantity })))
    const [currentTotalAdditionalPrice, setCurrentTotalAdditionalPrice] = useState(sumElements(orderedAdditionalProducts.map(item => {return Number(item.total.replace(/[^0-9.-]+/g,""))})))

    // DELIVERY FEE
    const [currentDeliveryFee, setCurrentDeliveryFee] = useState(calculateDeliveryFee((currentAdditionalBottlesCount + allocationBottlesCount), currentTotalAllocationPrice, currentTotalAdditionalPrice, selfPickUp, deliveryFeeOverride, initialDeliveryFee))

    const [grandTotal, setGrandTotal] = useState(currentTotalAllocationPrice + currentDeliveryFee + currentTotalAdditionalPrice)

    useEffect(() => props.setTotalAmount(grandTotal), [grandTotal]);

    useEffect(() =>
    {
        let newFee = calculateDeliveryFee((currentAdditionalBottlesCount + allocationBottlesCount), currentTotalAllocationPrice, currentTotalAdditionalPrice, selfPickUp, deliveryFeeOverride, initialDeliveryFee)
        setCurrentDeliveryFee(newFee)
        setGrandTotal(currentTotalAllocationPrice + newFee + currentTotalAdditionalPrice);
    },
        [selfPickUp]
    );


    const handleBottlesCountChange = (differenceBottles, differenceMoney) => {
        setCurrentAdditionalBottlesCount(currentAdditionalBottlesCount + differenceBottles)
        setCurrentTotalAdditionalPrice(currentTotalAdditionalPrice + differenceMoney)
        const newDeliveryFee = calculateDeliveryFee((allocationBottlesCount + currentAdditionalBottlesCount + differenceBottles), currentTotalAllocationPrice, (currentTotalAdditionalPrice + differenceMoney), selfPickUp, deliveryFeeOverride, initialDeliveryFee)

        setCurrentDeliveryFee(newDeliveryFee)
        setGrandTotal(currentTotalAllocationPrice + currentTotalAdditionalPrice + differenceMoney + newDeliveryFee)
    };

    const checkOrderedIfOrderPaid = () => {
        if (paid === false) {
            return true;
        } else {
            return orderedAdditionalProducts.filter(product => product.quantity !== 0).length > 0;
        }
    };

    const needToShowAdditionalSection = () => {
       return (typeof props.additionalProducts !== 'undefined' && props.additionalProducts.filter(product => product.quantity !== 0).length > 0 && checkOrderedIfOrderPaid() )
    }

    const thereIsNoAdditionalSection = () => {
       return needToShowAdditionalSection() == false
    }

    return (
        <div>
            <table className="table table-striped table-hover allocation-table">
                <thead>
                <tr>
                    <th>Wine</th>
                    <th>Vintage</th>
                    <th className="text-center">Price Per Bottle</th>
                    <th className="col-xs-1 cell-label cell-label--number">Quantity Requested</th>
                    <th className="col-xs-1 cell-label cell-label--number">Quantity Offered</th>
                    <th className="col-xs-1 cell-label cell-label--number">Total Price</th>
                </tr>
                </thead>
                <tbody>
                {allocationProducts.map(lineItem => <OrderLineItem key={lineItem.id} {...lineItem} />)}
                {/*{props.orderDetails.map(lineItem => <OrderLineItem key={lineItem.id} {...lineItem} />)}*/}
                <OrderTotal name="Total Allocation Bottles" value={allocationBottlesCount} />
                <OrderTotal name="Total for Allocation" currency={true} value={currentTotalAllocationPrice} />
                {/*<OrderTotal name="Total for Allocation" value={props.total} />*/}
                { thereIsNoAdditionalSection() && (
                    <Fragment>
                        <OrderTotal name="Total for Order" currency={true} value={grandTotal} />
                        <OrderTotal name="Delivery Fee" currency={true} value={currentDeliveryFee} />
                        <OrderTotal name="Grand Total inc. GST" currency={true} value={grandTotal} />
                    </Fragment>
                )}
                </tbody>


            </table>

            {needToShowAdditionalSection() && (
                <div>
                    <h2 className="text-center">
                        {props.paid === true ? '' : 'Select'} Optional Additional Wines
                    </h2>
                    <table className="table table-striped table-hover allocation-table additional-products-order">
                        <thead>
                        <tr>
                            <th>Wine</th>
                            <th>Vintage</th>
                            <th className="text-center">Price</th>
                            <th className="col-xs-1 cell-label cell-label--number">Quantity</th>
                            <th className="col-xs-1 cell-label cell-label--number">Total Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {/*{props.orderDetails.map(lineItem => <OrderAdditionalWineLineItem key={lineItem.id} {...lineItem} maxPerOrder={lineItem.maxQuantityPerOrder} />)}*/}
                        {props.additionalProducts.map(lineItem =>
                            <OrderAdditionalWineLineItem
                                key={lineItem.id}
                                {...lineItem}
                                maxPerOrder={lineItem.maxQuantityPerOrder}
                                quantitiesAvailableToCustomer={lineItem.quantitiesAvailableToCustomer}
                                handleBottlesCountChange={handleBottlesCountChange}
                                productsPath={props.productsPath}
                                orderId={props.orderId}
                                orderedAdditionalProducts={orderedAdditionalProducts}
                                paid={props.paid}
                            />)}
                        <OrderTotalAdditional name="Total Additional Bottles" value={currentAdditionalBottlesCount} />
                        <OrderTotalAdditional name="Total for Additional" currency={true} value={currentTotalAdditionalPrice} />
                        <tr className="order-total">
                            <td colSpan="5" className="cell-label cell-label--number" style={{backgroundColor:'#fffaf0'}}>
                                <br/>
                            </td>
                        </tr>
                        <OrderTotalAdditional name="Total for Order" currency={true} value={grandTotal - currentDeliveryFee} />
                        <OrderTotalAdditional name="Delivery Fee" currency={true} value={currentDeliveryFee} />
                        <OrderTotalAdditional name="Grand Total inc. GST" currency={true} value={grandTotal} />
                        </tbody>
                    </table>
                </div>
            )}


        </div>

    )
}
