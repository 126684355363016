import React from "react"

import classNames from "classnames"

import ControlGroup from "./ControlGroup"
import ControlLabel from "./ControlLabel"

export default ({
  className,
  errors,
  includeBlank,
  label,
  name,
  options = [],
  required,
  wrapperHTMLClassName,
  ...props
}) => {
  const id = name.replace(/\[/g, "_").replace(/\]/g, "")

  return (
    <ControlGroup
      className={["select", id, wrapperHTMLClassName]}
      error={!!errors}
      required={required}
    >
      <ControlLabel className="select" id={id} required={required}>
        {label}
      </ControlLabel>
      <div className="controls">
        <select
          className={classNames("form-control select", className, {
            required,
            optional: !required,
          })}
          id={id}
          name={name}
          required={required}
          aria-required={required}
          {...props}
        >
          {includeBlank !== false && <option value="" />}
          {options.map(([description, value, optionProps]) => (
            <option value={value} key={value} {...optionProps}>
              {description}
            </option>
          ))}
        </select>
        {errors && <span className="help-inline">{errors}</span>}
      </div>
    </ControlGroup>
  )
}
