// if amount in stock is higher than order max quantity: display order max quantity
// if amount in stock is less than order max quantity: display amount left in stock

import React, { useEffect, useState } from "react"
import { toast } from 'react-toastify';
import qs from 'qs';
import 'react-toastify/dist/ReactToastify.css';
import {
  SelectInput,
  TextInput,
  Form,
} from "./UI/Form"

toast.configure({
  hideProgressBar: true
})

export default (props) => {
  const formFieldName = (fieldName) => `${props.formName}[${fieldName}]`
  const [customers, setCustomers] = useState([]);

  const filterCustomers = (e) => {
    let customerNameValue = document.getElementById('customer-name').value
    let customerEmailValue = document.getElementById('customer-email').value
    let customerPhoneValue = document.getElementById('customer-phone').value
    let customerGroupValue = document.getElementById('customer-group').value

    const paramsObj = {
      q: {
        full_name_cont: customerNameValue,
        email_cont: customerEmailValue,
        telephone_business_or_telephone_home_or_telephone_mobile_or_telephone_international_cont: customerPhoneValue,
        customer_group_users_customer_group_id_eq: customerGroupValue
      }
    }

    return fetch(`${props.customerFilterPath}.json?` + new URLSearchParams(qs.stringify(paramsObj)), {
      credentials: "include",
      headers: {
        Accept: "application/json"
      },
    })
      .then(response => response.json())
      .then(response => {
        if (response.customers) {
          setCustomers(response.customers)
        } else {
          handleUnexpectedError("Unexpected error occurred while updating the order");
        }
        return response;
      });
  }

  return (
    <div className="customer-filter">
      <div className="container-fluid">
        <h2 className="text-center">Select Customer</h2>
        <Form className="simple-form" id="customer-form">
          <div className="row">
            <TextInput
              label="Customer Name"
              wrapperHTMLClassName="col-sm-3"
              id="customer-name"
              name={formFieldName("customer_name")}
            />
            <TextInput
              label="Email"
              wrapperHTMLClassName="col-sm-3"
              id="customer-email"
              name={formFieldName("customer_email")}
            />
            <TextInput
              label="Phone Number"
              wrapperHTMLClassName="col-sm-3"
              id="customer-phone"
              name={formFieldName("customer_phone")}
            />
            <SelectInput
              label="Customer Group"
              name={formFieldName("customer_group")}
              includeBlank={true}
              id="customer-group"
              options={props.customerGroups}
            />
          </div>
        </Form>
        <div className="form-actions">
          <button
            className="btn btn-primary btn-lg text-uppercase"
            name="commit"
            onClick={filterCustomers}
          >
            Filter
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <SelectInput
            label="Customers"
            name={formFieldName("customers")}
            includeBlank={false}
            id="customers"
            options={customers.map(i => [i.name, i.id])}
            style={{ width: '300px' }}
          />
        </div>
        <div className="form-actions">
          <button
            className="btn btn-primary btn-lg text-uppercase"
            name="commit"
            onClick={(e) => props.handleAddOrder(props.tableId)}
          >
            Add Member to {props.tableNumber}
          </button>
        </div>
      </div>
    </div>
  )
}
