import React from "react"

import classNames from "classnames"

export default ({ className, error, required, ...props }) => (
  <div
    className={classNames("form-group control-group", className, {
      error,
      required,
      optional: !required,
    })}
  >
    {props.children}
  </div>
)
