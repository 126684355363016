import React, { useEffect, useState } from "react"
import { toast } from 'react-toastify';
import { emitOrderUpdatedEvent } from './utils/events'
import ProductListItem from "./UI/ProductListItem";
import CustomerFilter from "./CustomerFilter";
import ShoppingCartButton from "./ShoppingCartButton";
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  hideProgressBar: true
})

const ShoppingCartErrorMessage = (props) => (
  <div>
    <h4>Order errors</h4>
    <ul>
      {Object.entries(props.errors).map(([key, value]) => <li><span>{key}</span> {value}</li>)}
    </ul>
  </div>
)

const OrderLineItem = props => {
  return(
    <tr>
      <td>{props.shortName}</td>
      <td className="text-right">{props.price}</td>
      <td className="text-right">{props.quantity}</td>
      <td className="text-right">{props.total}</td>
    </tr>
  )
}

export default (props) => {
  const [order, setOrder] = useState({})
  const [orderPath, setOrderPath] = useState(props.orderPath)
  const [showCustomerFilter, setShowCustomerFilter] = useState(false);
  const [customerName, setCustomerName] = useState(props.customerName);
  const [membershipStatus, setMembershipStatus] = useState(props.membershipStatus);

  useEffect(() => fetchOrder(), []);
  useEffect(() => {
    document.addEventListener('orderUpdated', (e) => {
      setOrder(e.detail.order);
      setOrderPath(e.detail.order.url);
    })
  }, []);

  const addToCart = (productId, quantity, currentOrderId = null) => {
    if (quantity.toString().length == 0) {
      return;
    }

    let body = new FormData();
    body.append("quantity", quantity);
    if (currentOrderId) {
      body.append("order_id", currentOrderId);
    }

    return fetch(`${props.productsPath}/${productId}`, {
      body: body,
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
      .then(response => response.json())
      .then(response => {
        if (Object.keys(response.order.errors).length === 0) {
          emitOrderUpdatedEvent(response.order);
        } else {
          toast(<ShoppingCartErrorMessage {...response.order} />, { className: "shopping-cart-popup" })
        }
        return response;
      })
      .catch(error => {
        toast(<ShoppingCartErrorMessage errors={ { error: "Unexpected error occurred"} } />, { className: "shopping-cart-popup" })
      });
  }

  const fetchOrder = () => {
    fetch(`${orderPath}.json`, {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
    .then(response => response.json())
    .then(response => {
      emitOrderUpdatedEvent(response.order);
    });
  }

  const ChangeCustomer = () => {
    setShowCustomerFilter(!showCustomerFilter);
  };

  const addOrder = (foodTableId, customerId = null) => {  
    let userId;
    if (customerId) {
      userId = customerId
    } else {
      userId = document.getElementById('customers').value
    }
  
    if (!userId) {
      toast(<ShoppingCartErrorMessage errors={{ error: "Select Customer above before adding" }} />, { className: "shopping-cart-popup" })
      return
    }

    let body = new FormData();
    body.append("food_table_id", foodTableId);
    body.append("user_id", userId);

    return fetch(`${props.foodTablesAddOrderPath}`, {
      body: body,
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
      .then(response => response.json())
      .then(response => {
        if (Object.keys(response.foodTable.errors).length === 0) {
          setShowCustomerFilter(false);
          setCustomerName(response.foodTable.customerName);
          setMembershipStatus(response.foodTable.membershipStatus);
        } else {
          toast(<ShoppingCartErrorMessage {...response.foodTable} />, { className: "shopping-cart-popup" })
        }
        return response;
      })
      .catch(error => {
        toast(<ShoppingCartErrorMessage errors={ { error: "Unexpected error occurred"} } />, { className: "shopping-cart-popup" })
      });
  }

  const abandonTable = (foodTableId) => {  
    let body = new FormData();
    body.append("food_table_id", foodTableId);

    return fetch(`${props.foodTablesAbandonTablePath}`, {
      body: body,
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
      .then(response => response.json())
      .then(response => {
        if (Object.keys(response.foodTable.errors).length === 0) {
          window.open(`${props.foodTablesPath}`, '_self')
        } else {
          toast(<ShoppingCartErrorMessage {...response.foodTable} />, { className: "shopping-cart-popup" })
        }
        return response;
      })
      .catch(error => {
        toast(<ShoppingCartErrorMessage errors={ { error: "Unexpected error occurred"} } />, { className: "shopping-cart-popup" })
      });
  }
  
  return (
    <div>
      <div className="row">
        {showCustomerFilter && (
          <CustomerFilter
            customerFilterPath={props.customerFilterPath}
            customerGroups={props.customerGroups}
            formName="food_table"
            tableId={props.id}
            tableNumber={props.tableNumber}
            handleAddOrder={addOrder}
          />
        )}
      </div>
      <div className="row food-table-item">
        <div className="col-9 products-list">
          {props.products.length > 0 && (
            <div className="row">
              {props.products.map(productListItem => <ProductListItem
                  key={productListItem.id}
                  handleAddToCart={addToCart}
                  currentOrderId={props.currentOrderId}
                  itemType={"foodTable"}
                  {...productListItem}
                />
              )}
            </div>
          ) || (<div className="products-unavailable text-center">
            Wines for sale by the bottle are not currently available. We will notify you when the next release of wines becomes available.
          </div>)}
        </div>
        <div className="col-6 products-list-additional">
          <div className="row">
            <div className="col-12 table-name text-center">
              Table {props.tableNumber}
            </div>
          </div>
          <div className="row">
            <div className="col-6 customer-name-title">
              Customer Name
            </div>
            <div className="col-6 customer-name">
              {customerName}
            </div>
          </div>
          <div className="row">
            <div className="col-6 customer-name-title">
              Membership Status
            </div>
            <div className="col-6 customer-name">
              {membershipStatus}
            </div>
          </div>
          {(order.orderDetails) && (order.orderDetails.length > 0) && (
            <div className="order-details">
              <div className="row">
                <div className="col-3"></div>
                <div className="col-6">
                  <ShoppingCartButton
                    orderPath={props.orderPath}
                  />
                </div>
              </div>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th className="col-xs-1 cell-label cell-label--number">QTY</th>
                    <th className="col-xs-1 cell-label cell-label--number">Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderDetails.map(lineItem => <OrderLineItem key={lineItem.id} {...lineItem} />)}

                  <tr className="order-total">
                    <td className="cell-label cell-label--number" colSpan="3">
                      <strong>Grand Total inc. GST</strong>
                    </td>
                    <td className="cell-data cell-data--number">{order.total}</td>
                  </tr>
                </tbody>
              </table>
              <div className="row gap">
              </div>
              <div className="row">
                <div className="col-6">
                  <button onClick={(e) => ChangeCustomer()} className="mt-auto btn btn-block btn-primary btn-lg text-uppercase">
                    Change Customer
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="mt-auto btn btn-block btn-primary btn-lg text-uppercase"
                    onClick={(e) => {
                      if (window.confirm('ARE YOU SURE?\nTHIS CANNOT BE UNDONE')) {
                        abandonTable(props.id)
                      }
                    }} 
                  >
                    Abandon Table
                  </button>
                </div>
              </div>
            </div> )}
        </div>
      </div>
    </div>
  )
}
