import React, { useEffect, useState } from "react"

export default props => {
  const [customerName, setCustomerName] = useState(props.customerName)
  const [membershipStatus, setmembershipStatus] = useState(props.membershipStatus)

  useEffect(() => {
    document.addEventListener('orderUpdated', (e) => {
      let currentFoodTable = e.detail.order.id == props.id;

      if (currentFoodTable == false) {
        return
      }

      setCustomerName(e.detail.order.customerName)
      setmembershipStatus(e.detail.order.membershipStatus)
    });
  }, '');

  const foodTableInfoFull = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="food-table-name">
            Table {props.tableNumber}
          </div>
        </div>
      </div>
      {customerName && (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="customer-name-title">
                Customer Name
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="customer-name">
                {customerName}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="customer-name-title">
                Membership Status
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="customer-name">
                {membershipStatus}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
      <div className="card food-table-card text-center mb-4">
        <div className="d-flex flex-column align-items-end card-body">
          {foodTableInfoFull()}
          {!customerName && (
            <button onClick={(e) => props.handleAddOrder(props.id, props.tableGuestId)} className="mt-auto btn btn-block btn-black btn-lg text-uppercase">Add Guest</button>
          )}
          {!customerName && (
            <button onClick={(e) => props.handleAddMember(props.id, props.tableNumber)} className="btn btn-block btn-black btn-lg text-uppercase">Add Member</button>
          )}
          {customerName && (
            <button onClick={(e) => window.open(`${props.foodTablesPath}/${props.id}`, '_self')} className="mt-auto btn btn-block btn-black btn-lg text-uppercase">Add Product</button>
          )}
        </div>
      </div>
    </div>
  );
}
