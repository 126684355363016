import React, { useEffect, useState } from "react"
import {HiddenInput, SelectInput} from "./Form";

const OrderLineItem = props => {
  return(
    <tr>
      <td colSpan={2}>
        {props.vintageYear} {props.wineName} {props.size}
        {!props.countsTowardsShippingCost && (
          <span className="badge badge-secondary free-shipping">
            <i className="fa fa-truck"></i> Free Shipping
          </span>
        )}
      </td>
      <td className="text-center">{props.price}</td>

      {props.orderPaid === true && (
        <td className="text-center">{props.quantity}</td>
      ) || (
        <td className="text-center">
          <HiddenInput
            name={`order[order_details_attributes][${props.id}][id]`}
            defaultValue={props.id}
          />
          <SelectInput
            name={`order[order_details_attributes][${props.id}][quantity]`}
            defaultValue={props.quantity}
            onChange={props.handleOrderUpdate}
            errors={props.errors.quantity}
            includeBlank={false}
            options={[...Array(200).keys()].map(i => [i, i])}
          />
        </td>
      )}
      <td className="text-right">{props.total}</td>
    </tr>
  )
}

const OrderTotal = props => {
  return(
    <tr className="order-total">
      <td className="cell-label cell-label--number" colSpan="4">
        <strong>{props.name}</strong>
      </td>
      <td className="cell-data cell-data--number">{props.value}</td>
    </tr>
  )
}

export default props => {
  return (
    <table className="table table-striped table-hover allocation-table">
      <thead>
      <tr>
        <th colSpan={2}>Product</th>
        <th className="text-center">Price</th>
        <th className="col-xs-1 cell-label cell-label--number text-center">Quantity</th>
        <th className="col-xs-1 cell-label cell-label--number text-right">Total Price</th>
      </tr>
      </thead>
      <tbody>
        {props.orderDetails.map(lineItem => <OrderLineItem
          key={lineItem.id}
          handleOrderUpdate={props.handleOrderUpdate}
          orderPaid={props.status == 'paid'}
          {...lineItem}
        />)}
        <OrderTotal name="Delivery Fee" value={props.deliveryFee} />
        <OrderTotal name="Grand Total inc. GST" value={props.grandTotal} />
      </tbody>
    </table>
  )
}
