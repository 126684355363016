// if amount in stock is higher than order max quantity: display order max quantity
// if amount in stock is less than order max quantity: display amount left in stock

import React, { useEffect, useState } from "react"
import { toast } from 'react-toastify';
import { emitOrderUpdatedEvent } from './utils/events'
import ProductListItem from "./UI/ProductListItem";
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
  hideProgressBar: true
})

const ShoppingCartErrorMessage = (props) => (
  <div>
    <h4>Order errors</h4>
    <ul>
      {Object.entries(props.errors).map(([key, value]) => <li><span>{key}</span> {value}</li>)}
    </ul>
  </div>
)

export default (props) => {
  console.log(props.products);
  const addToCart = (productId, quantity) => {
    if (quantity.toString().length == 0) {
      return;
    }

    let body = new FormData();
    body.append("quantity", quantity);

    return fetch(`${props.productsPath}/${productId}`, {
      body: body,
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
      .then(response => response.json())
      .then(response => {
        if (Object.keys(response.order.errors).length === 0) {
          emitOrderUpdatedEvent(response.order);
        } else {
          toast(<ShoppingCartErrorMessage {...response.order} />, { className: "shopping-cart-popup" })
        }
        return response;
      })
      .catch(error => {
        toast(<ShoppingCartErrorMessage errors={ { error: "Unexpected error occurred"} } />, { className: "shopping-cart-popup" })
      });
  }

  const productGroups = (products) =>
    products.reduce((groups, product) => {
      const customerGroups = product.customerGroups.includes("Cellar Door")
        ? ["Cellar Door"]
        : product.customerGroups;

      customerGroups.forEach((customerGroup) => {
        groups[customerGroup] = groups[customerGroup] || {};

        const wineTypeName = product.wineType.name;

        groups[customerGroup][wineTypeName] = groups[customerGroup][wineTypeName] || [];

        groups[customerGroup][wineTypeName].push(product);

        // Sort each group by displaySequence
        groups[customerGroup][wineTypeName].sort((a, b) => {
          const aSequence = a.displaySequence || Infinity;
          const bSequence = b.displaySequence || Infinity;
          return aSequence - bSequence;
        });
      });

      return groups;
    }, {});

  return (
    <div>
      {props.products.length > 0 ? (
        Object.entries(productGroups(props.products))
          .sort((a, b) => b[0].localeCompare(a[0])) // Sort customer groups
          .map(([customerGroup, wineTypes]) => (
            <div key={customerGroup}>
              <div className="row page-title">
                <h2>{customerGroup}</h2>
              </div>
              {Object.entries(wineTypes).map(([wineTypeName, products]) => (
                <div key={wineTypeName}>
                  <div className="row page-title">
                    <h4 style={{fontWeight: 'bold'}}>{wineTypeName} Wines</h4>
                  </div>
                  <div className="row">
                    {products.map((productListItem) => (
                      <ProductListItem
                        key={productListItem.id}
                        handleAddToCart={addToCart}
                        {...productListItem}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))
      ) : (
        <div className="products-unavailable text-center">
          Wines for sale by the bottle are not currently available. We will notify you when the next release of wines becomes available.
        </div>
      )}
    </div>
  );
}
