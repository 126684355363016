import React from "react"

import classNames from "classnames"

import ControlGroup from "./ControlGroup"
import ControlLabel from "./ControlLabel"
import HiddenInput from "./HiddenInput"

export default ({
  className,
  errors,
  label,
  name,
  required,
  wrapperHTMLClassName,
  message,
  ...props
}) => {
  const id = name.replace(/\[/g, "_").replace(/\]/g, "")
  
  return (
    <ControlGroup
      className={["boolean", id, wrapperHTMLClassName]}
      error={!!errors}
      required={required}
    >
      <div className="checkbox">
        <HiddenInput name={name} value="0"></HiddenInput>
        <ControlLabel className="boolean" id={id + "_checkbox"} required={required}>
          <input
            className={classNames("boolean", className, {
              required,
              optional: !required,
            })}
            id={id + "_checkbox"}
            type="checkbox"
            name={name}
            value="1"
            required={required}
            aria-required={required}
            {...props}
          />
          {label}
        </ControlLabel>
        {errors && <p><span className="help-inline error_message">{errors}</span></p>}
      </div>
    </ControlGroup>
  )
}
