import React from "react"

import classNames from "classnames"

import ControlGroup from "./ControlGroup"
import ControlLabel from "./ControlLabel"

export default ({
  className,
  errors,
  includeBlank,
  label,
  name,
  options = [],
  required,
  wrapperHTMLClassName,
  ...props
}) => {
  const id = name.replace(/\[/g, "_").replace(/\]/g, "")

  return (
    <ControlGroup
      className={["radio_buttons", id, wrapperHTMLClassName]}
      error={!!errors}
      required={required}
    >
      {options.map(([description, value, optionProps]) => (
        <span className="radio" key={value}>
          <ControlLabel id={value} required={required} key={value}>
            {label}
            <input
              className={classNames("radio_buttons", className, {
                required,
                optional: !required,
              })}
              id={value}
              name={name}
              value={value}
              style={{display: `${value == '3'? 'none' : ''}`}}
              key={value}
              type="radio"
              onChange={ e => {
                if (typeof props.setSelfPickUp === 'function') {props.setSelfPickUp(e.target.value)}
                props.onChange;
              }}
              defaultChecked={value == '3'}
              {...optionProps}
            >
            </input>
            {description}
          </ControlLabel>
        </span>
      ))}
      {errors && <p><span className="help-inline error_message">{errors}</span></p>}
    </ControlGroup>
  )
}
