import React, { useEffect, useState } from 'react';

import { emitOrderUpdatedEvent } from './utils/events'

export default WrappedComponent => (props) => {
  const [order, setOrder] = useState({})
  const [awaitingResponse, setAwaitingResponse] = useState(false)

  useEffect(() => fetchOrder(), []);

  const fetchOrder = () => {
    let ignore = false;

    fetch(`${props.orderPath}.json`, {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    }).then(response => response.json())
    .then(response => {
      // Overriding the order status based on the callback from the payment gateway
      if (window.location.search.includes("summarycode=1")) {
        response.order.status = 'paid'
      }

      if (!ignore) setOrder(response.order);
    });

    return () => { ignore = true; }
  }

  const updateOrder = ({ checkout = false, paymentType = null }) => {
    let body = new FormData(document.getElementById('order-form'))

    body.append("checkout", checkout);
    body.append("payment_type", paymentType);

    return fetch(props.orderPath, {
      body: body,
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
    })
    .then(response => response.json())
    .then(response => {
      if (response.order) {
        setOrder(response.order)
        emitOrderUpdatedEvent(response.order)
      } else {
        handleUnexpectedError("Unexpected error occurred while updating the order");
      }
      return response;
    });
  }

  const displayOrder = () => {
    if (Object.keys(order).length === 0) {
      return (<p className="text-center">Loading...</p>)
    } else {
      return (<WrappedComponent
        order={order}
        handleOrderUpdate={updateOrder}
        awaitingResponse={awaitingResponse}
        {...props}
      />);
    }
  }

  return displayOrder();
};
